.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#react-select-3-input{
  outline: none !important;
  border-color: inherit !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

#react-select-3-input:focus{
  outline: none !important;
  border-color: inherit !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  
}

.ant-select-selector {
  min-height: 40px;
  border-radius: 5px !important;
}

.ant-select-selection-placeholder {
  color: rgb(107, 114, 128);
}

.ant-btn-primary {
  --tw-bg-opacity: 1;
    background-color: rgba(50, 124, 251, var(--tw-bg-opacity)) !important;
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
}

.ant-btn-primary:hover, .ant-dropdown-trigger:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 105, 161, var(--tw-bg-opacity)) !important;
}

.ant-dropdown-trigger {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 00.375rem !important;
  border-bottom-right-radius: 00.375rem !important;
  border-left-color: #e4e9ec !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fix-modal-gs > .relative,
.fix-modal-gs > form {
  padding-bottom: 30px;
}

.fix-modal-gs {
  padding-bottom: 100px;
}
.ant-menu-inline {
  border-left: 1px solid #f0f0f0;
  border-right: none;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color: transparent
}


.force-5px-border-radius {
  border-radius: 5px !important;
}

.circle{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  }

  .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::before {
    position: absolute;
    top: 0;
    /* right: 0; */
    bottom: 0;
    border-right: 3px solid #1890ff;
    transform: scaleY(0.0001);
    /* opacity: 0; */
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    content: '';
}

.ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::before {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  display: flex;
  align-items: center;
  transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
  padding-left: 0px !important;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 24px;
}
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  visibility: hidden;
}

.collapse-container-ncms {
  border: none;
}

.collapse-container-ncms > .ant-collapse-item {
  border-bottom: none;
}

.collapse-panel-ncms {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  border: 0;
  border-bottom: none;
}

.collapse-panel-ncms > .ant-collapse-header {
  width: 100%;
  background-color: #F1F6FF;
  border-radius: 5px !important;
}

.collapse-panel-ncms > .ant-collapse-content {
  width: 100%;
}


.collapse-container-custom {
  border: none;
}

.collapse-container-custom > .ant-collapse-item {
  border-bottom: none;
}

.collapse-panel-custom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  border: 0;
  border-bottom: none;
}

.collapse-panel-custom > .ant-collapse-header {
  width: 100%;
}
.collapse-panel-custom > .ant-collapse-header > .ant-collapse-header-text {
  color: #327CFB;
}

.collapse-panel-custom > .ant-collapse-content {
  width: 100%;
}

.collapse-container-create {
  border: none;
}

.collapse-container-create > .ant-collapse-item {
  border-bottom: none;
}

.collapse-panel-create {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  border: 0;
  border-bottom: none;
}


.collapse-panel-create > .ant-collapse-header  {
  width: 100%;
  align-items: inherit !important;
  background-color: #F3F4F6;
  border-radius: 5px !important;
  border-color: #F3F4F6;
}

.collapse-panel-create > .ant-collapse-content {
  width: 100%;
  border: gray;
}

.anticon {
  vertical-align: 1.8px !important;
}

.ant-pagination-item-active {
  background-color: #0f2956;
  border-radius: 5px !important;
  border-color: #0f2956;
}
.ant-pagination-item-active a {
  color: white;  
}

.ant-pagination-item-active:hover {
  border-color: #0f2956;
}


.ant-btn .ant-btn-default{
  border-radius: 5px !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
